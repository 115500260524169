<template>
  <WidgetFrame  @onResize="onResize" @onOrientationChange="onOrientationChange">
    <template v-slot:title>
      {{ widgetData.name }}
    </template>
    <template v-slot:editor>
      <div :id="`DATAVIEW_WIDGET_BTN_ADD-${widget.uuId}`" v-on:click="onEdit" :class="{mobile: isTouchScreen}"><i class="far fa-pen-to-square"></i></div>
      <b-popover :target="`DATAVIEW_WIDGET_BTN_ADD-${widget.uuId}`" triggers="hover" placement="top" boundary="viewport">
        {{ $t('button.edit') }}
      </b-popover>
    </template>
    <template v-slot:content>
      <template v-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else>
        <div class="dataview-container">
          <div v-if="widgetData.image" class="dataview-header" :style="{'background-image': `url(${bannerUrl})` }"></div>
          <div v-if="widgetData.description" class="dataview-description">{{ widgetData.description }}</div>
          <div>
            <Dataview :isWidget="true" :dataviewId="widgetData.dataview.uuId" :widgetOwner="widgetData.owner" :dataviewPublic="widgetData.dataview.isPublic" :height="height - 85" :width="width" :dataviewComponent="widgetData.dataview.id"/>
          </div>
        </div>
        
        <DashboardDataviewModal
          v-if="dataviewShow"
          :widget="widget"
          :readOnly="!isOwner(widget)"
          @ok="dataviewUpdated"
          @cancel="modalCancel"
          />
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
import WidgetFrame from "@/components/Dashboard/WidgetFrame";
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import { profileService } from '@/services';

export default {
  name: 'LaunchpadWidget',
  components: {
    WidgetFrame,
    Dataview: () => import('@/views/dataview/Dataview.vue'),
    DashboardDataviewModal: () => import('@/components/modal/DashboardDataviewModal.vue')
  },
  props: {
    widget: { type: Object, required: true, default: function() {return null} },
    project: { type: Object, required: false, default: function() {return null} },
  },
  watch: {
    widget(newWidget) {
      this.widgetData = this.widget;
      if (this.widget.originalUuid) {
        profileService.get(this.widget.originalUuid).then((response) =>  {
          const w = response.data[response.data.jobCase];
          if (w.length > 0) {
            this.widgetData = w[0];
          }
        })
        .catch(e => {
          this.widgetData = this.widget;
        }); 
      }
    }
  },
  computed: {
    isTouchScreen() {
      return window.matchMedia("(pointer: coarse)").matches
    },
    bannerUrl() {
      return `${this.baseUrl}api/file/${this.widget.image}`;
    },
  },
  data() {
    return {
      orientation: null,
      width: 0,
      height: 0,
      loaded: false,
      dataviewShow: false,
      userId: null,
      widgetData: null
    };
  },
  created() {
    this.loaded = true;
    this.userId = this.$store.state.authentication.user.uuId;
    this.widgetData = this.widget;
    if (this.widget.originalUuid) {
      profileService.get(this.widget.originalUuid).then((response) =>  {
        const w = response.data[response.data.jobCase];
        if (w.length > 0) {
          this.widgetData = w[0];
        }
      })
      .catch(e => {
        this.widgetData = this.widget;
      }); 
    }
  },
  mounted() {
    
  },
  methods: {
    isOwner(widget) {
      return widget.owner === this.userId && !widget.originalUuid;
    },
    onOrientationChange({orientation, width, height}) {
      this.orientation = orientation;
      this.onResize({width, height});
    },
    onResize({width, height}) {
      this.width = width;
      this.height = height;
    },
    modalCancel() {
      this.dataviewShow = false;
    },
    onEdit() {
      this.dataviewShow = true;
    },
    dataviewUpdated(widget) {
      this.$emit('dataviewUpdated', widget);
      this.dataviewShow = false;
    }
  }
}
</script>


<style lang="scss" scoped>
  .launchpad-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
  }
  .launchpad-header {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    flex: 1;
    min-height: 35%;
    margin-bottom: 10px;
  }
  .launchpad-description {
    color: var(--text-light);
    text-align: center;
    white-space: pre-line;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .launchpad-link {
    display: block !important;
    margin: 5px;
  }
</style>