
<template>
  <li class="working-card mb-2">
    <div>{{ label }}</div>
    <template v-for="(value, index) in entry">
      <div :key="index" v-if="value.calendarName == calendarName || entry.length == 1">
        <b-row class="py-1">
          <b-col sm>
            <div class="d-flex">
              <Timepicker class="d-block mr-1" v-model="value.startHour"  @input="change(value)" 
                :readOnly="readOnly || (!canAdd() && (typeof value.uuId === 'undefined' || value.uuId.includes('EXCEPTION'))) || (!canEdit() && value.uuId != null && !value.uuId.includes('EXCEPTION'))" 
                :disableTime="readOnly || (!canAdd() && (typeof value.uuId === 'undefined' || value.uuId.includes('EXCEPTION'))) || (!canEdit() && value.uuId != null && !value.uuId.includes('EXCEPTION'))"
                @context="onContext($event, index, 'startHour')">
              </Timepicker>
              <Timepicker class="d-block mr-1" v-model="value.endHour" @input="change(value)" 
                :readOnly="readOnly || (!canAdd() && (typeof value.uuId === 'undefined' || value.uuId.includes('EXCEPTION'))) || (!canEdit() && value.uuId != null && !value.uuId.includes('EXCEPTION'))"
                :disableTime="readOnly || (!canAdd() && (typeof value.uuId === 'undefined' || value.uuId.includes('EXCEPTION'))) || (!canEdit() && value.uuId != null && !value.uuId.includes('EXCEPTION'))"
                @context="onContext($event, index, 'endHour')">
              </Timepicker>
              <template v-if="!readOnly">
                <div class="d-block mr-1">
                  <b-button :id="`WORKING_ACTION_ADD_${id}_${index}`" v-if="!readOnly && canAdd()" variant="secondary" @click="addEntry(value)">
                    <font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      :target="`WORKING_ACTION_ADD_${id}_${index}`"
                      placement="top"
                      boundary="viewport"
                      triggers="hover"
                      :content="$t('button.add')">
                    </b-popover>
                  </b-button>
                </div>
                <div class="d-block mr-1">
                  <b-button :id="`WORKING_ACTION_DELETE_${id}_${index}`" v-if="!readOnly && canDelete()" :disabled="index === 0" variant="secondary" @click="removeEntry(value)">
                    <b-popover
                      :target="`WORKING_ACTION_DELETE_${id}_${index}`"
                      placement="top"
                      boundary="viewport"
                      triggers="hover"
                      :content="$t('button.delete')">
                    </b-popover>
                    <font-awesome-icon :icon="['far', 'trash-can']"/>
                  </b-button>
                </div>
              </template>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
    <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
      <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
    </b-alert>
  </li>
</template>

<script>

import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);

import { strRandom, objectClone } from '@/helpers';

export default {
  name: 'WorkingDay',

  components: {
    Timepicker: () => import('@/components/Calendar/Timepicker.vue')
  },
  props: {
    entry:         { type: Array },
    calendarName:  { type: String },
    readOnly:      { type: Boolean, default: false },
    dayIndex:      { type: Number, default: 0 }
  },
  data() {
    return {
      id: `WORKING_DAY_${strRandom(5)}`,
      permissionName: 'CALENDAR',
      alertMsg: null
    };
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    },
    label() {
      if (this.entry.length > 0) {
        return moment().day(this.dayIndex).format('dddd')
      }
      return '';
    }
  },
  watch: {
    entry(newValue) {
      if (Array.isArray(newValue) && newValue.length > 0) {
        //Reset the validation state when value is reassigned.
        this.validate();
      }
    }
  },
  methods: {
    addEntry(value, replace) {
      if (this.entry.length >= 10) {
        this.alertMsg = this.$t('working_day.error.max_10_entries');
        return;
      }
      this.$emit('add', { value: value, replace: replace });
      this.$forceUpdate(); 
      this.validate();
    },
    removeEntry(value) {
      this.$emit('remove', value);
      this.$forceUpdate(); 
      this.validate();
    },
    validate(value) {
      // validation
      if (this.multipleDateRangeOverlaps(this.entry)) {
        this.alertMsg = this.$t('working_day.error.date_range_overlap');
      } else {
        this.alertMsg = null;
        if (typeof value !== 'undefined') {
          if ((value.startHour != null && value.endHour == null)) {
            this.alertMsg = this.$t('working_day.error.missing_end_time');
          } else if (value.startHour == null && value.endHour != null) {
            this.alertMsg = this.$t('working_day.error.missing_start_time');
          } else {
            const startDate = new Date('1970-01-01T' + value.startHour).getTime();
            const endDate = value.endHour == '00:00' || value.endHour == '00:00:00'? new Date('1970-01-02T' + value.endHour).getTime() : new Date('1970-01-01T' + value.endHour).getTime();
            if (startDate >= endDate && '00:00:00' != value.startHour) {
              this.alertMsg = this.$t('working_day.error.start_time_less_than_end_time');
            }
          }
          
          // else {
          //   for (var i = 0; i < this.entry.length; i++) {
          //     if (i !== 0) {
          //       const startDate = new Date('1970-01-01T' + this.entry[i].startHour).getTime();
          //       const prevStartDate = new Date('1970-01-01T' + this.entry[i-1].startHour).getTime();
          //       if (startDate < prevStartDate) {
          //         this.alertMsg = this.$t('working_day.error.start_time_must_be_in_chronological_order');
          //         break;
          //       }
          //     }
          //   }
          // }
        }
      }
      this.$emit('canSave', this.alertMsg === null);
    },
    change(value) {
      if ((typeof value.uuId === 'undefined' || (value.uuId.indexOf('NEW_EXCEPTION') === -1 && value.calendarName !== this.calendarName))) {
        this.addEntry(value, true);    
      }
      this.validate(value);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    dateRangeOverlaps(a_start, a_end, b_start, b_end) {
      if (a_start === b_end) return true; // start and end cannot overlap
      if (a_start < b_start  && b_start < a_end) return true; // b starts in a
      if (a_start < b_end    && b_end   < a_end) return true; // b ends in a
      if (b_start < a_start  && a_end  < b_end) return true; // a in b
      if (a_start == b_start || a_end == b_end) return true;
      return false;
    },
    multipleDateRangeOverlaps(timeEntries) {
      //sort
      const sorted = objectClone(timeEntries).sort((a, b) => {
        if (a == null && b == null) {
          return 0;
        }
        if (a == null || a.calendarName == null) {
          return -1;
        }
        if (b == null || b.calendarName == null) {
          return 1;
        }

        if (a.calendarName < b.calendarName) {
          return -1;
        }
        if (a.calendarName > b.calendarName) {
          return 1;
        }

        if (a.startHour == null) {
          return -1;
        }
        if (b.startHour == null) {
          return 1;
        }

        if (a.startHour == b.startHour) {
          if (a.endHour == null) {
            return -1;
          }
          if (b.endHour == null) {
            return 1;
          }
          if ( a.endHour > b.endHour) {
            return 1;
          }
          return -1;
        }
        if (a.startHour > b.startHour) {
          return 1;
        }
        return -1;
      });
      
      let i = 0, j = 0;
      if (sorted != null && sorted.length > 1)
      for (i = 0; i < sorted.length - 1; i += 1) {
        for (j = i + 1; j < sorted.length; j += 1) {
          if (sorted[i].calendarName != sorted[j].calendarName) {
            continue;
          }
          if (this.dateRangeOverlaps(
              new Date('1970-01-01T' + sorted[i].startHour).getTime(), sorted[i].endHour == '00:00' || sorted[i].endHour == '00:00:00'? new Date('1970-01-02T' + sorted[i].endHour).getTime() : new Date('1970-01-01T' + sorted[i].endHour).getTime(),
              new Date('1970-01-01T' + sorted[j].startHour).getTime(), sorted[j].endHour == '00:00' || sorted[j].endHour == '00:00:00'? new Date('1970-01-02T' + sorted[j].endHour).getTime() : new Date('1970-01-01T' + sorted[j].endHour).getTime(),
              )) {
            return true;
          }
        }
      }
      return false;
    },
    onContext(ctx, idx, key) {
      if(ctx.hours != null && ctx.minutes == null){
        this.entry[idx][key] = `${ctx.hours}:00:00`;
        //Call forceUpdate twice to make sure the minute value shows up in GUI.
        this.$forceUpdate();
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    }
  }
}
</script>

<style lang="scss">

.working-card .b-time-minutes output.border-bottom.border-top {
  border-bottom: none !important;
  border-top: none !important;
}
</style>