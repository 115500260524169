var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetFrame", {
    on: {
      onResize: _vm.onResize,
      onOrientationChange: _vm.onOrientationChange,
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.widgetData.name) + " ")]
        },
        proxy: true,
      },
      {
        key: "editor",
        fn: function () {
          return [
            _c(
              "div",
              {
                class: { mobile: _vm.isTouchScreen },
                attrs: { id: `DATAVIEW_WIDGET_BTN_ADD-${_vm.widget.uuId}` },
                on: { click: _vm.onEdit },
              },
              [_c("i", { staticClass: "far fa-pen-to-square" })]
            ),
            _c(
              "b-popover",
              {
                attrs: {
                  target: `DATAVIEW_WIDGET_BTN_ADD-${_vm.widget.uuId}`,
                  triggers: "hover",
                  placement: "top",
                  boundary: "viewport",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "dataview-container" }, [
                    _vm.widgetData.image
                      ? _c("div", {
                          staticClass: "dataview-header",
                          style: {
                            "background-image": `url(${_vm.bannerUrl})`,
                          },
                        })
                      : _vm._e(),
                    _vm.widgetData.description
                      ? _c("div", { staticClass: "dataview-description" }, [
                          _vm._v(_vm._s(_vm.widgetData.description)),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("Dataview", {
                          attrs: {
                            isWidget: true,
                            dataviewId: _vm.widgetData.dataview.uuId,
                            widgetOwner: _vm.widgetData.owner,
                            dataviewPublic: _vm.widgetData.dataview.isPublic,
                            height: _vm.height - 85,
                            width: _vm.width,
                            dataviewComponent: _vm.widgetData.dataview.id,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.dataviewShow
                    ? _c("DashboardDataviewModal", {
                        attrs: {
                          widget: _vm.widget,
                          readOnly: !_vm.isOwner(_vm.widget),
                        },
                        on: {
                          ok: _vm.dataviewUpdated,
                          cancel: _vm.modalCancel,
                        },
                      })
                    : _vm._e(),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }